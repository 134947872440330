export default {
    back_to_overview: 'back to overview',
    order: 'Order',
    order_type: 'Type',
    order_type_preorder: 'Pre-order',
    order_type_kern: 'KERN-order',
    shipment: 'Shipment',
    designation: 'Designation',
    ordered_from: 'Ordered from',
    order_date: 'Order date',
    show_partial_orders: 'Show partial orders',
    available: 'Available',
    fedas_nr: 'Fedas Nr.',
    model_nr: 'Model Nr.',
    model_variant_nr: 'Model / Nr / Variants',
    criteria_core: 'Auftragsart',
    models: 'Mod',
    variant: 'Variant',
    size: 'Size',
    pieces: 'Num',
    pieces_dot: 'Pcs.',
    pieces_short: 'Pcs.',
    positions: 'Pos',
    percentage_up: '%Up',
    percentage_off: '%Off',
    pieces_ek: 'Pieces EK',
    pieces_upe: 'Pieces UPE',
    net_ek: 'Net Ek',
    sum_ek: 'Sum EK',
    sum_upe: 'Sum UPE',
    remark: 'Note',
    order_details_remark_text: 'Internal comment on the product. Partially absent, then hidden.',
    summary: 'Summary',
    subtotal: 'Subtotal',
    minus_discounts: 'minus discounts',
    total: 'Total',
    order_details: 'Order details',
    product: 'Product',
    product_img: 'Product image',
    brand: 'Brand',
    dealer_margin: 'DM',
    amount: 'Amount',
    limit_period: 'Limit period',
    search_cart: 'Search cart',
    search_orders: 'model-no., order no., cart name, member name',
    search_orders_member: 'model no., order no., cart name,',
    upe: 'UPE',
    ek: 'EK',
    supplier: 'Supplier',
    discount: 'Discount',
    branch: 'Branch',
    entire_order: 'Entire order',
    shortcuts: 'HASP=Trade margin',
    branch_division: 'Branch division',
    in_queue: 'In queue...',
    approve: 'Approve',
    in_progress: 'in progress',
    order_overview: 'Order overview',
    cart_name: 'Cart name',
    shipments: 'Shipments',
    details: 'Details',
    criteria_branch: 'Branch',
    criteria_brand: 'Brand',
    criteria_supplier: 'Supplier',
    criteria_delivery_date: 'Delivery date',
    criteria_delivery_address: 'Delivery address',
    criteria_division: 'Division',
    criteria_ultimate_consignee: 'Goods final recipient',
    member: 'Member',
    member_number: 'Memb. Nr.',
    member_name: 'Member name',
    printed: 'Print',
    actions: 'Actions',
    description: 'Description',
    discounts_summary: 'You can find applied discounts for each shipment in the final price.',
    discounts_details_summary: 'Applied discounts can be found at the item level in the discount price.',
    no_discount_applied: 'No discount applied',
    partial_order: 'Teilbestellung',
    partially_printed: 'Not all shipments were printed.',
    fully_printed: 'All shipments were printed.',
    print_selection: 'Print selection',
    reexport_selection: 'Export again',
    reexport_started: 'Re-export has started!',
    reexport_triggered: 'Re-export successfully triggered!',
    reexport_trigger_failed: 'Re-export has failed!',
    quantity: 'Quantity',
    conditions_short: 'COND.',
    season: 'Season',
    promotion: 'Promotion',
    nos: 'NOS',
    house_vk: 'House-VK',
    order_summary: 'Summary of total order',
    ek_gross: 'EK Gross',
    ek_net: 'EK Net',
    conditions: 'Conditions',
    is_releasing_singular: 'One item will be released',
    is_releasing_plural: '{count} items will be released',
    is_releasing_info: 'You don\'t need to do anything else',
    refresh_overview: 'Refresh overview',
    reexport_modal_head: 'Re-export',
    reexport_modal_text: 'Do you really want to re-export the selected orders? The re-export will be available in your system within the next hour.',
    reexport_export_button: 'Confirm',
};
