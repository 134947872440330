import { default as _91_46_46_46slug_93VHI8vV7XxDMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46category_id_93lRscDmEgZSMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/c/[...category_id].vue?macro=true";
import { default as _91cart_id_93k6AxoC8hI8Meta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/cart/[cart_id].vue?macro=true";
import { default as contacto6OL4qZRAiMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/contact.vue?macro=true";
import { default as faq9xbbIrSe34Meta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/faq.vue?macro=true";
import { default as homeVEKVp6TknLMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/home.vue?macro=true";
import { default as imprintJvA0VrCeKmMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/imprint.vue?macro=true";
import { default as indexQ972earyOTMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/index.vue?macro=true";
import { default as login_45postH1wfaTLEtQMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/login-post.vue?macro=true";
import { default as additional_45header_45fields4hnYq497KkMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/additional-header-fields.vue?macro=true";
import { default as addressesVRhZ6511M2Meta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/addresses.vue?macro=true";
import { default as branch_45clusterswcRLNINDvtMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/branch-clusters.vue?macro=true";
import { default as cartsJK5RKswlY6Meta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/carts.vue?macro=true";
import { default as condition_45sheets1VSrwMNvbbMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/condition-sheets.vue?macro=true";
import { default as evaluationcr18oT1wf8Meta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/evaluation.vue?macro=true";
import { default as order_45detailsymvLp7VOE4Meta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/order-details.vue?macro=true";
import { default as order_45historyfMOY3211sGMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/order-history.vue?macro=true";
import { default as preferencesBT3uXpHvNjMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/preferences.vue?macro=true";
import { default as my_45accountH3499hEvnfMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account.vue?macro=true";
import { default as _91id_939EDDezruVlMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/p/[id].vue?macro=true";
import { default as privacy_45policyduIR8zLwr7Meta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/privacy-policy.vue?macro=true";
import { default as _91_46_46_46slug_93UgMhBa6YNjMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/product-notes/[...slug].vue?macro=true";
import { default as smsR1qJObfzI5Meta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/sms.vue?macro=true";
import { default as _91_46_46_46category_id_93quZmuGAODYMeta } from "/codebuild/output/src1284646418/src/intersport-order-frontend/pages/workbook/[...category_id].vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93VHI8vV7XxDMeta || {},
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "c-category_id",
    path: "/c/:category_id(.*)*",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/c/[...category_id].vue").then(m => m.default || m)
  },
  {
    name: "cart-cart_id",
    path: "/cart/:cart_id()",
    meta: _91cart_id_93k6AxoC8hI8Meta || {},
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/cart/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "imprint",
    path: "/imprint",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login-post",
    path: "/login-post",
    meta: login_45postH1wfaTLEtQMeta || {},
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/login-post.vue").then(m => m.default || m)
  },
  {
    name: "my-account",
    path: "/my-account",
    meta: my_45accountH3499hEvnfMeta || {},
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account.vue").then(m => m.default || m),
    children: [
  {
    name: "my-account-additional-header-fields",
    path: "additional-header-fields",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/additional-header-fields.vue").then(m => m.default || m)
  },
  {
    name: "my-account-addresses",
    path: "addresses",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "my-account-branch-clusters",
    path: "branch-clusters",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/branch-clusters.vue").then(m => m.default || m)
  },
  {
    name: "my-account-carts",
    path: "carts",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/carts.vue").then(m => m.default || m)
  },
  {
    name: "my-account-condition-sheets",
    path: "condition-sheets",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/condition-sheets.vue").then(m => m.default || m)
  },
  {
    name: "my-account-evaluation",
    path: "evaluation",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/evaluation.vue").then(m => m.default || m)
  },
  {
    name: "my-account-order-details",
    path: "order-details",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/order-details.vue").then(m => m.default || m)
  },
  {
    name: "my-account-order-history",
    path: "order-history",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/order-history.vue").then(m => m.default || m)
  },
  {
    name: "my-account-preferences",
    path: "preferences",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/my-account/preferences.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "p-id",
    path: "/p/:id()",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/p/[id].vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "product-notes-slug",
    path: "/product-notes/:slug(.*)*",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/product-notes/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "sms",
    path: "/sms",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/sms.vue").then(m => m.default || m)
  },
  {
    name: "workbook-category_id",
    path: "/workbook/:category_id(.*)*",
    component: () => import("/codebuild/output/src1284646418/src/intersport-order-frontend/pages/workbook/[...category_id].vue").then(m => m.default || m)
  }
]