export default {
    back_to_overview: 'Zurück zur Übersicht',
    order: 'Bestellung',
    order_type: 'Art',
    order_type_preorder: 'Vororder',
    order_type_kern: 'Kernauftrag',
    shipment: 'Lieferung',
    designation: 'Bezeichnung',
    ordered_from: 'Bestellt von',
    order_date: 'Bestelldatum',
    show_partial_orders: 'Teilbestellungen anzeigen',
    available: 'Verfügbar',
    fedas_nr: 'Fedas Nr.',
    model_nr: 'Modell-Nr.',
    model_variant_nr: 'Modell / Nr / Varianten',
    criteria_core: 'Auftragsart',
    models: 'Mod',
    variant: 'Variante',
    size: 'GR',
    pieces: 'Stk',
    pieces_dot: 'Stk.',
    pieces_short: 'Stck.',
    positions: 'Pos',
    percentage_up: '%Auf',
    percentage_off: '%Ab',
    pieces_ek: 'Stk. EK',
    pieces_upe: 'Stk. UPE',
    net_ek: 'Netto Ek',
    sum_ek: 'Endpreis',
    sum_upe: 'UPE',
    remark: 'Notiz',
    additional_order_fields: 'Zusatzkopffelder',
    order_details_remark_text:
        'Interner Kommentar zum Produkt. Teilweise nicht vorhanden, dann ausgeblendet.',
    summary: 'Zusammenfassung',
    subtotal: 'Zwischensumme',
    minus_discounts: 'abzüglich Rabatte',
    total: 'Gesamtsumme',
    order_details: 'Bestelldetails',
    product: 'Produkt',
    product_img: 'Produktbild',
    brand: 'Marke',
    dealer_margin: '%AB',
    amount: 'Menge',
    limit_period: 'Zeitraum eingrenzen',
    search_cart: 'Warenkorb suchen',
    search_orders: 'Model-Nr., Bestell-Nr., WK-Name, Mitgl.-Name',
    search_orders_member: 'Model-Nr., Bestell-Nr., WK-Name',
    upe: 'UPE',
    ek: 'EK',
    supplier: 'Lieferant',
    discount: 'Rabatt',
    branch: 'Filiale',
    entire_order: 'Gesamte Bestellung',
    shortcuts: '%AB=Handelsspanne',
    branch_division: 'Filialaufteilung',
    in_queue: 'In Warteschlange...',
    approve: 'Freigabe',
    in_progress: 'wird freigegeben',
    order_overview: 'Bestellübersicht',
    cart_name: 'Name Warenkorb',
    shipments: 'Lieferungen',
    details: 'Details',
    criteria_branch: 'Filiale',
    criteria_brand: 'Marke',
    criteria_supplier: 'Lieferant',
    criteria_delivery_date: 'Liefertermin',
    criteria_delivery_address: 'Lieferadresse',
    criteria_division: 'Aufteilung',
    criteria_ultimate_consignee: 'Waren-Endempfänger',
    member: 'Mitglied',
    member_number: 'Mitgl.-Nr.',
    member_name: 'Mitgliedsname',
    printed: 'Druck',
    actions: 'Aktionen',
    description: 'Beschreibung',
    discounts_summary: 'Angewendete Rabatte finden Sie je Shipment beim Endpreis.',
    discounts_details_summary: 'Angewendete Rabatte finden Sie auf Positionsebene beim Rabatt.',
    no_discount_applied: 'Kein Rabatt angewendet',
    partial_order: 'Teilbestellung',
    partially_printed: 'Es wurden nicht alle Teillieferungen gedruckt.',
    fully_printed: 'Alle Teilliefrungen wurden gedruckt.',
    print_selection: 'Auswahl drucken',
    reexport_selection: 'Erneut exportieren',
    reexport_started: 'Re-Export gestartet!',
    reexport_triggered: 'Re-Export erfolgreich getriggert.',
    reexport_trigger_failed: 'Re-Export fehlgeschlagen!',
    quantity: 'Anzahl',
    conditions_short: 'KOND.',
    season: 'Saison',
    promotion: 'Aktion',
    nos: 'NOS',
    house_vk: 'Haus-VK',
    order_summary: 'Zusammenfassung Gesamtbestellung',
    ek_gross: 'EK Brutto',
    ek_net: 'EK Netto',
    conditions: 'Konditionen',
    is_releasing_singular: 'Eine Freigabe wird verarbeitet',
    is_releasing_plural: '{count} Freigaben werden verarbeitet',
    is_releasing_info: 'Sie müssen nichts weiter tun',
    refresh_overview: 'Übersicht aktualisieren',
    reexport_modal_head: 'Erneut exportieren',
    reexport_modal_text: 'Möchten Sie wirklich die ausgewählten Bestellungen re-exportieren? Der Re-Export wird innerhalb der nächsten Stunde in Ihrem System bereitgestellt.',
    reexport_export_button: 'Bestätigen',
};
