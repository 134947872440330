export default {
    load_error: 'Fehler beim laden der Daten {0}',
    load_error_title: 'Fehler',
    load_error_text: 'Ein Fehler beim Laden der Daten ist aufgetreten. Bitte laden Sie die Seite neu.',
    addresses: 'Adressen',
    order_history: 'Bestellungen',
    carts: 'Warenkörbe',
    shopping_lists: 'Merklisten',
    condition_sheets: 'Konditionsverwaltung',
    additional_header_fields: 'Zusatzkopffelder',
    preferences: 'Präferenzen',
    shipping_address_desc:
        "Die verfügbaren Empfänger-Adressen können über das <a href='https://portal.intersport.de/irj/portal?NavigationTarget=navurl://2b5bd9fcb411fca4bf49c1cc2dae3552' target='_blank'>Intersport Portal</a> hinzugefügt und verwaltet werden.",
    categories: 'Kategorien',
    filters: 'Filters',
    sort_by: 'Sortieren nach',
    products_found: 'Produkte gefunden',
    about_us: 'Über uns',
    who_we_are: 'Wer wir sind',
    quality_in_the_details: 'Qualität im Detail',
    customer_reviews: 'Kundenbewertungen',
    departments: 'Abteilungen',
    women_fashion: 'Damenmode',
    men_fashion: 'Herrenmode',
    kidswear: 'Kinderkleidung',
    home: 'Startseite',
    help: 'Hilfe',
    customer_service: 'Kundendienst',
    size_guide: 'Größentabelle',
    contact_us: 'Kontaktiere uns',
    payment_and_delivery: 'Zahlung & Lieferung',
    purchase_terms: 'Kaufbedingungen',
    guarantee: 'Garantie',
    read_reviews: 'Bewertungen lesen',
    additional_information: 'Zusätzliche Information',
    save_for_later: 'Für später speichern',
    add_to_compare: 'Hinzufügen zum vergleichen',
    match_it_with: 'Kombiniere es mit',
    share_your_look: 'Teile deinen Look',
    product_description:
        'Das Karissa V-Neck Tee hat eine halb taillierte Form schmeichelhaft für jede Figur. Sie können mit ins Fitnessstudio gehen Vertrauen, während es Kurven umarmt und häufiges "Problem" verbirgt Bereiche. Finden Sie atemberaubende Cocktailkleider für Frauen und feiern Sie Kleider.',
    brand: 'Marke',
    instruction1: 'Um mich kümmern',
    instruction2: 'Nur hier für die Pflegehinweise?',
    instruction3: 'Ja, das haben wir uns gedacht',
    items: 'Gegenstände',
    view: 'Ansicht',
    'show on page': 'Auf Seite anzeigen',
    done: 'Fertig',
    clear_all: 'Alles löschen',
    empty:
        'Sieht so aus, als hätten Sie der Tasche noch keine Artikel hinzugefügt. Beginnen Sie mit dem Einkaufen, um es auszufüllen.',
    help_and_faqs: 'Hilfe & FAQs',
    download_application: 'Laden Sie unsere Anwendung herunter',
    find_out_more: 'Finde mehr heraus',
    more: 'Mehr',
    login: 'Anmeldung',
    forgotten_password: 'Passwort vergessen?',
    go_to_checkout: 'Zur Kasse gehen',
    go_back_shopping: 'Zurück einkaufen',
    personal_details: 'Persönliche Daten',
    edit: 'Bearbeiten',
    shipping_details: 'Versanddetails',
    client_address: 'Rechnungsadresse',
    client: 'Auftraggeber',
    shipping_address_per_branch: 'Lieferadresse (je Filiale)',
    shipping_address: 'Lieferadresse',
    end_receiver_shipping_address: 'Endempfänger Lieferadressen',
    standard_end_receiver_shipping_address: 'Standard Endempfänger Adresse',
    all_available_addresses: 'Alle verfügbaren Adressen',
    end_receiver_modal_header: 'Alle verfügbaren Endempfänger Adressen zu Filiale ',
    default_address: 'Standard-Adresse',
    alternative_address: 'Alternativ-Adresse',
    same_as_shipping_address: 'Wie Versandadresse',
    payment_method: 'Zahlungsmethode',
    apply: 'Übernehmen',
    update_password: 'Passwort aktualisieren',
    update_personal_data: 'Persönliche Daten aktualisieren',
    item: 'Artikel',
    go_back: 'Go back',
    continue_to_shipping: 'Weiter zum Versand',
    i_agree_to: 'Ich stimme zu',
    terms_and_conditions: 'Allgemeine Geschäftsbedingungen',
    pay_for_order: 'Für Bestellung bezahlen',
    log_into_your_account: 'In dein Konto einloggen',
    or_fill_the_details_below: 'oder füllen Sie die Details unten',
    enjoy_your_free_account: 'Enjoy these perks with your free account!',
    continue_to_payment: 'Weiter zur Zahlung',
    order_no: 'Bestellnummer',
    successful_placed_order:
        'Sie haben die Bestellung erfolgreich aufgegeben. Sie können den Status Ihres Bestellen Sie über unsere Lieferstatusfunktion. Sie erhalten eine Bestellung Bestätigungs-E-Mail mit Details Ihrer Bestellung und einem Link zum Verfolgen der Bestellung Fortschritt.',
    info_after_order:
        'Sie können sich mit E-Mail und definiertem Passwort in Ihrem Konto anmelden vorhin. Überprüfen Sie in Ihrem Konto Ihre Profildaten Transaktionsverlauf, Abonnement für Newsletter bearbeiten.',
    allow_order_notifications: 'Bestellbenachrichtigungen zulassen',
    feedback: 'Ihr Feedback ist uns wichtig. Lassen Sie uns wissen, was wir verbessern können.',
    send_my_feedback: 'Senden Sie mein Feedback',
    go_back_to_shop: 'Zurück zum Einkaufen',
    read_all_reviews: 'Alle Bewertungen lesen',
    color: 'Farbe',
    contact_details_updated: 'Halten Sie Ihre Adressen und Kontaktdaten auf dem neuesten Stand.',
    manage_billing_addresses:
        'Alle gewünschten Rechnungsadressen verwalten (Arbeitsplatz, Privatadresse ...) Auf diese Weise müssen Sie die Rechnungsadresse nicht bei jeder Bestellung manuell eingeben.',
    change: 'Änderungsänderung',
    delete: 'Löschen',
    delete_confirm: 'Möchten Sie dieses Element wirklich löschen?',
    add_new_address: 'Neue Adresse hinzufügen',
    set_up_newsletter:
        'Richten Sie Ihren Newsletter ein und wir senden Ihnen wöchentlich Informationen zu neuen Produkten und Trends aus den von Ihnen ausgewählten Bereichen',
    'sections_that_interest you': 'Abschnitte, die Sie interessieren',
    save_changes: 'Änderungen speichern',
    read_and_understand: 'Ich habe das gelesen und verstanden',
    privacy: 'Datenschutz',
    cookies_policy: 'Cookie-Richtlinie',
    commercial_information:
        'und erklären sich damit einverstanden, personalisierte Handelsinformationen vom Markennamen per E-Mail zu erhalten',
    feel_free_to_edit:
        'Fühlen Sie sich frei, Ihre unten stehenden Daten zu bearbeiten, damit Ihr Konto immer auf dem neuesten Stand ist',
    use_your_personal_data:
        'Bei Markennamen legen wir großen Wert auf Datenschutzfragen und verpflichten uns, die persönlichen Daten unserer Benutzer zu schützen. Erfahren Sie mehr darüber, wie wir Ihre persönlichen Daten pflegen und verwenden',
    privacy_Policy: 'Datenschutzrichtlinie',
    change_password_your_account:
        'Wenn Sie das Passwort ändern möchten, um auf Ihr Konto zuzugreifen, geben Sie die folgenden Informationen ein',
    'your_current_email_ address_is': 'Ihre aktuelle E-Mail-Adresse lautet',
    product: 'Produkt',
    details_and_status_orders:
        'Überprüfen Sie die Details und den Status Ihrer Bestellungen im Online-Shop. Sie können Ihre Bestellung auch stornieren oder eine Rücksendung anfordern. ',
    you_currently_have_no_orders: 'Sie haben derzeit keine Bestellungen',
    start_shopping: 'Einkaufen starten',
    download: 'Herunterladen',
    download_all: 'Alle herunterladen',
    view_details: 'Details anzeigen',
    manage_shipping_addresses:
        'Alle gewünschten Versandadressen verwalten (Arbeitsplatz, Privatadresse ...) Auf diese Weise müssen Sie die Versandadresse nicht bei jeder Bestellung manuell eingeben.',
    quantity: 'Menge',
    price: 'Preis',
    back_to_homepage: 'Zurück zur Homepage',
    select_shipping_method: 'Versandart auswählen',
    review_my_order: 'Meine Bestellung überprüfen',
    select_payment_method: 'Zahlungsmethode auswählen',
    make_an_order: 'Bestellung aufgeben',
    or: 'oder',
    login_in_to_your_account: 'Anmelden bei Ihrem Konto',
    create_an_account: 'Konto erstellen',
    your_bag_is_empty: 'Ihre Tasche ist leer',
    cancel: 'Abbrechen',
    cancel_all: 'Alle Abbrechen',
    see_all_results: 'Alle Ergebnisse anzeigen',
    you_haven_not_searched_for_items_yet: 'Sie haben noch nicht nach Artikeln gesucht.',
    let_us_start_now_we_will_help_you: 'Fangen wir jetzt an - wir helfen Ihnen.',
    search_results: 'Suchergebnisse',
    product_suggestions: 'Produktvorschläge',
    search_for_items: 'Nach Artikeln suchen',
    search_for_notes: 'Nach Notizen suchen',
    c_name: 'C-Name',
    stp_name: 'STP-Name',
    gln: 'GLN',
    c_number: 'KundenNr',
    address: 'Adresse',
    branch: 'Filiale',
    customer_account: 'Mein Konto',
    orders: 'Bestellungen',
    shipments: 'Lieferungen',
    logout: 'Abmelden',
    name: 'Name',
    created_at: 'Erstellungsdatum',
    number_articles: 'Anzahl Artikel',
    empty_shopping_list_name: 'Bitte fügen sie einen Namen für die Merkliste ein',
    change_name: 'Name ändern',
    save_change: 'Änderung speichern',
    confirm_delete_wishlist_cart_modal_title:
        'Bist du dir sicher, dass du die Merkliste löschen möchtest?',
    confirm_delete_wishlist_cart_modal_message:
        'Gelöschte Merklisten können nicht wiederhergestellt werden.',
    yes_delete: 'Ja, löschen',
    page: 'Seite',
    of: 'von',
    go_to: 'Gehe zu',
    apply_2: 'Anwenden',
    search: 'Suchen',
    limit_date: 'Datum eingrenzen',
    from: 'Von',
    to: 'Bis',
    enter_search_term: 'Suchbegriff eingeben...',
    shopping_cart: 'Warenkorb',
    shopping_carts: 'Warenkörbe',
    created: 'Erstellt',
    approved: 'Freigegeben',
    purchase_sum: 'Einkaufssumme',
    supplier: 'Lieferant',
    suppliers: 'Lieferanten',
    dealer_margin: 'Händlerspanne',
    partial_orders: 'Teil-Bestellungen',
    save: 'Speichern',
    product_has_been_added_to: 'Artikel wurde zu {shopping-list-name} hinzugefügt.',
    back: 'Zurück',
    'what_are_you_looking_for?': 'Wonach suchen Sie?',
    matrix: 'Matrix',
    total: 'Preis',
    create: 'Anlegen',
    delete_all_products: 'Alle Produkte löschen',
    product_short_model_number: 'M.Nr.',
    ek: 'EK',
    upe: 'UPE',
    net_ek: 'Netto-EK',
    checkout: 'Kasse',
    intersport_portals: 'INTERSPORT Portale',
    intersport_portal_looged_out: 'INTERSPORT Portal.',
    faq: 'Fragen & Antworten',
    portal_support: 'Portal Support',
    sales_team: 'Sales Team',
    symbol_quantity: 'Stck.',
    total_cart: 'Alle Warenkörbe',
    create_new_cart: 'Neuen Warenkorb erstellen',
    article: 'Artikel',
    active: 'Aktiv',
    description: 'Bezeichnung',
    update_at: 'Bearbeitet',
    ek_sum: 'EK Summe',
    upe_sum: 'UPE Summe',
    sum: 'Summe',
    last_edited: 'Zuletzt bearbeitet',
    total_price: 'Gesamt Preis:',
    ok: 'OK',
    sign_in: 'Einloggen',
    home_page_title: 'Intersport Vororder',
    close: 'Schließen',
    login_title: 'Das Vororder Portal wird geladen',
    login_text: 'Einen Moment bitte. Sie werden eingeloggt...',
    login_failed_title: 'Hoppla, da hat etwas nicht funktioniert',
    login_failed_text:
        'Wir konnten Sie nicht in das Vororder-Portal einloggen. Versuchen Sie es erneut oder probieren Sie es über das Intersport Portal',
    login_failed_button_portal: 'Zum Intersport Portal',
    login_failed_button_retry: 'Erneut versuchen',
    intersport_portal: 'Intersport Portal',
    auth_failed: 'Authentifizierung fehlgeschlagen',
    help_and_service: 'Hilfe & Service',
    home_banner: 'Willkommen im Vororder Shop',
    home_video_tutorials_headline: 'Video Tutorials',
    marketing_action: 'SALE',
    all_categories: 'Alle Kategorien',
    select_placeholder: 'Bitte Option auswählen',
    invalid_input: 'Ungültige Eingabe!',
    under_construction: 'Under Construction',
    under_construction_text: 'Hier entsteht bald ein neuer Bereich in der Vororder. Bitte kommen Sie später wieder.',
    error_page_subheadline: 'Entschuldigung, da ist<br> wohl etwas schief gelaufen!',
    error_page_text: 'Wir konnten die Seite nicht finden. Bitte versuchen Sie es erneut oder kontaktieren Sie uns.',
    to_login: 'Zum Login',
    try_again: 'Nochmals versuchen',
    to_homepage: 'Zur Startseite',
    platforms: 'Plattformen',
    ci_chips_headline: 'Beliebte Verkaufssysteme',
    ci_brands_headline: 'Unsere Top Brands',
    help_and_service_route: "https://intersport-idea.atlassian.net/servicedesk/customer/portal/3/user/login?destination=portal%2F3",
    ci_important_messages: "Wichtige Mitteilungen",
    ci_no_messages: "Derzeit gibt es keine Mitteilungen.",
    welcome: 'Herzlich Willkommen',
    notes: "Notizen",
    workbook: 'Workbook',
    downloads_loading_text: 'Dateien werden bereitgestellt. Bitte laden Sie die Seite nicht neu während des Vorgangs.',
    uploads_loading_text: 'Dateien werden hochgeladen. Bitte laden Sie die Seite nicht neu während des Vorgangs.',
    downloads_done_text: 'Dateien wurden erfolgreich bereitgestellt.',
    uploads_done_text: 'Dateien wurden erfolgreich hochgeladen.',
    downloads_error_text: 'Einige Dateien konnten nicht bereitgestellt werden. Bitte versuchen Sie es erneut.',
    uploads_error_text: 'Einige Dateien konnten nicht hochgeladen werden. Bitte versuchen Sie es erneut.',
    reexport_trigger_failed: 'Der Re-Export konnte nicht getriggert werden. Bitte versuchen Sie es erneut.',
    download_started: 'Download {0} gestartet',
    download_ended: 'Download {0} abgeschlossen',
    upload_started: 'Upload {0} gestartet',
    upload_ended: 'Upload {0} abgeschlossen',
    generate_print: 'Ausdruck generieren',
    download_as_pdf: 'Ausgewählte als Gesamt-pdf herunterladen',
    download_as_zip: 'Einzel-pdfs als .zip File herunterladen',
    faq_page_title: 'Intersport - FAQ',
    conditions_page_title: 'Intersport - AGB',
    contact_page_title: 'Intersport - Kontakt',
    delivery_regulations_page_title: 'Intersport - Liefer- & Versand-Vorschriften',
    imprint_page_title: 'Intersport - Impressum',
    privacy_policy_page_title: 'Intersport - Datenschutz',
    branch_cluster: 'Filialklasse',
    branch_clusters: 'Filialklassen',
    upload_branch_clusters: 'Filialklasse hochladen',
    branch_clusters_download_template: 'Vorlage herunterladen',
    branch_clusters_download_module_template: 'Modulvorlage herunterladen',
    branch_clusters_upload_warning: 'Entschuldigung, das hat nicht funktioniert. Wir können die CSV-Datai nicht verarbeiten.',
    file: 'Datei',
    product_group: 'WGR',
    branches: 'Filialen',
    date: 'Datum',
    branch_clusters_info_tooltip: 'Es werden alle Zeilen berücksichtigt, unabhängig davon, ob sie befüllt sind oder nicht',
    branch_cluster_file_upload_success: 'Die Datei wurde erfolgreich hochgeladen.',
    branch_cluster_file_upload_failure: 'Die Datei konnte nicht hochgeladen werden.',
    template_csv: 'Vorlage.csv',
    file_delete_successful: 'Dateien wurden erfolgreich gelöscht.',
    file_delete_failed: 'Dateien konnten nicht gelöscht werden. Bitte versuchen Sie es neu.',
    sms_title: 'SMS',
    sms_title_name: 'Sortimentsmanagementsystem',
    video_tutorial_img_alt: 'Video Tutorials',
    pre_order_portal: 'Vororder-Portal',
    reorder_portal: 'Nachorder-Portal',
    evaluation: "Auswertung"
};
